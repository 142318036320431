import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from './Header.styles'

const Header = ({ background, title, info }) => {
  const [isHomepage, setIsHomepage] = useState(false)

  useEffect(() => {
    if (window.location.pathname === '/') {
      setIsHomepage(true)
    }
  }, [])

  return (
    <S.Header
      homepage={isHomepage}
      background={background}
    >
      <S.Content homepage={isHomepage}>
        <S.Headline>{title}</S.Headline>
        <S.Info>{info}</S.Info>
      </S.Content>
    </S.Header>
  )
}

Header.defaultProps = {
  title: ``,
  info: ``
}

Header.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string
}

export default Header
