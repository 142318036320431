import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  ::selection {
    background-color: #104278;
    color: #fff;
  }

  @font-face {
    font-family: 'Sanomat Web';
    font-weight: 400;
    src: url('/fonts/Sanomat Web Regular Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Guardian Sans';
    font-weight: 100;
    src: url('/fonts/GuardianSansThin.woff') format('woff');
  }

  @font-face {
    font-family: 'Guardian Sans';
    font-weight: 400;
    src: url('/fonts/GuardianSansRegular.woff') format('woff');
  }

  @font-face {
    font-family: 'Guardian Sans';
    font-weight: 500;
    src: url('/fonts/GuardianSansSemibold.woff') format('woff');
  }

  html, body {
    font: 16px 'Guardian Sans', sans-serif;
    letter-spacing: .5px;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    color: rgb(49,48,47);
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Sanomat Web', serif;
  }

  .react-responsive-modal-modal {
    margin: auto !important;
  }
`

export const ContactSection = styled.div`
  background: rgb(147,149,152);
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
`

export const Page = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`

export default GlobalStyle
