import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import * as S from './Overview.styles'

const Overview = () => (
  <Row>
    <Col md={5} sm={12} xs={12}>
      <S.Title>Investing In People</S.Title>
      <S.SText>
        We partner with exceptional founders who are building great companies and play key roles in driving and shaping the future.
      </S.SText>
      <S.Line />
      <S.Title>Supporting With Expertise</S.Title>
      <S.SText>
        Strong expertise in out target sectors, global footprint and relationships allow us to meaningfully help our portfolio companies realize their full potential.
      </S.SText>
      <S.Line />
      <S.Title>Providing Growth Capital</S.Title>
      <S.SText>
        We aim to build a global portfolio of top-tier startups. Mission Gate builds relationships and partners with seed and early stage startups by providing them with privileged access to growth funds.
      </S.SText>
    </Col>
    <Col md={7} sm={12} xs={12}>
      <S.Image src='/images/3about.jpg' />
    </Col>
  </Row>
)

export default Overview
