import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'react-flexbox-grid'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import Container from '../Container'

import * as S from './Navbar.styles'

import navbar from '../../config/navbar.json'

const Navbar = () => {
  const [themeChange, setThemeChange] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [toggleMenu, setToggleMenu] = useState(false)
  const [changeLogo, setChangeLogo] = useState(false)

  useEffect(() => {
    setChangeLogo(window.innerWidth <= 768)
    window.addEventListener('scroll', () => {
      setScroll(true)

      if (window.scrollY > 10) {
        setThemeChange(true)
      } else {
        setThemeChange(false)
      }
    })
    return () => window.removeEventListener('scroll', () => { setScroll(false) })
  }, [scroll])

  const handleToggle = () => { setToggleMenu(!toggleMenu) }

  return (
    <S.Navbar
      themeChange={themeChange}
    >
      <Container>
        <Row between='sm' middle='sm'>
          <Col md={2} sm={12}>
            <Row between='xs' middle='xs'>
              <Link to='/'>
                { themeChange || changeLogo ?
                  <img alt='logo' src='/images/logo_dark.svg' height='48px' /> :
                  <img alt='logo' src='/images/logo_light.svg' height='48px' />
                }
              </Link>
              <S.Toggler
                icon={faBars}
                onClick={handleToggle}
              />
            </Row>
          </Col>
          <S.StyledCol md={10} sm={12}>
            <S.Menu>
              { navbar.links.map((l, i) => (
                <S.MenuItem themeChange={themeChange} key={i}>
                  <Link to={l.path} activeClassName='active'>{l.title}</Link>
                </S.MenuItem>
              )) }
            </S.Menu>
            <S.MobileMenu toggle={toggleMenu}>
              { navbar.links.map((l, i) => (
                <S.MobileMenuItem themeChange={themeChange} key={i}>
                  <Link to={l.path}>{l.title}</Link>
                </S.MobileMenuItem>
              )) }
            </S.MobileMenu>
          </S.StyledCol>
        </Row>
      </Container>
    </S.Navbar>
  )
}

export default Navbar
