import styled from 'styled-components'

export const Primary = styled.button`
  background: #104278;
  font-size: 1em;
  font-weight: 500;
  color: rgb(209, 211, 212);
  padding: .625em .9375em;
  text-transform: uppercase;
  border: 1px solid #104278;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${props => props.gray ? 'rgb(147,149,152)' : '#fff'};
    color: #104278;
  }
`
