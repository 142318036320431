import React, { useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Swiper from 'react-id-swiper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import 'swiper/css/swiper.css'
import * as S from './Slider.styles'

const Slider = () => {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1)

  const slides = [
    {
      image: '/images/Investment_focus.jpg',
      title: 'Rapidly growing, emerging industries and innovative, industry-disrupting ideas',
      teaser: 'We at Mission Gate strive to fuel entrepreneurs in industries that are currently emerging and support innovators with industry-disruptive ideas. Our investments span across blockchain, fin-tech, and innovative customer product spaces.'
    }, {
      image: '/images/Investment_stage.jpg',
      title: 'Companies in early stages of development',
      teaser: 'We view ourselves as early enablers – providing entrepreneurs with industry insights, the network of partners, mentors and talent, along with the needed growth capital.'
    }, {
      image: '/images/Investment_location.jpg',
      title: 'No Geographic Focus',
      teaser: 'In today’s globalized economy, we believe innovative products and ideas can be found around the world. We have invested in companies in Silicon Valley as well as in Eastern Europe and the Caribbean.'
    }
  ]

  const params = {
    loop: true,
  }

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const slideTo = n => {
    if (swiper !== null) {
      swiper.slideTo(n)
      setActiveIndex(n)
    }
  }

  return (
    <S.Slider>

    <S.Pagination>
      <S.Paginate active={activeIndex === 1} onClick={() => slideTo(1)}>INVESTMENT FOCUS</S.Paginate>
      <S.Paginate active={activeIndex === 2} onClick={() => slideTo(2)}>INVESTMENT STAGE</S.Paginate>
      <S.Paginate active={activeIndex === 3} onClick={() => slideTo(3)}>LOCATION</S.Paginate>
    </S.Pagination>
      <Row middle='md' between='md' style={{ margin: 0 }}>
        <S.NavButton onClick={() => {
          goPrev()
          setActiveIndex(swiper.activeIndex % 3 || 3)
        }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </S.NavButton>
        <Col md={10}>
          <Swiper {...params} getSwiper={setSwiper}>
            { slides.map((s, i) => (
              <S.Slide key={i}>
                <Row middle='md'>
                  <Col md={6} sm={12} xs={12}>
                    <S.Image src={s.image} />
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <S.Title>{s.title}</S.Title>
                    <S.Teaser>{s.teaser}</S.Teaser>
                  </Col>
                </Row>
              </S.Slide>
            )) }
          </Swiper>
        </Col>
        <S.NavButton onClick={() => {
          goNext()
          setActiveIndex(swiper.activeIndex % 3 || 3)
        }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </S.NavButton>
      </Row>
    </S.Slider>
  )
}

export default Slider
