import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

import Container from '../Container'

import * as S from './Footer.styles'

const Footer = () => (
  <S.Footer>
    <Container>
      <Row between='md'>
        <S.Column md={3}>
          <S.Title>Quick Links</S.Title>
          <S.SLink to='/'>Home</S.SLink>
          <S.SLink to='/about'>Who we are</S.SLink>
        </S.Column>
        <S.Column md={5}>
          <Row>
            <Col md={6} sm={12}>
              <S.Title>Explore Mission Gate</S.Title>
              <S.SLink to='/companies'>Companies</S.SLink>
              <S.SLink to='/team'>People</S.SLink>
              <S.SLink to='/insights'>Insights</S.SLink>
            </Col>
          </Row>
        </S.Column>
        <S.Column md={3}>
          <S.Title>Get In Touch</S.Title>
          <S.SLink to='/contact'>Contact</S.SLink>
          <S.Social
            target='blank'
            href='https://www.facebook.com/missiongateVC/'
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </S.Social>
          <S.Social
            target='blank'
            href='https://www.twitter.com'
          >
            <FontAwesomeIcon icon={faTwitter} />
          </S.Social>
        </S.Column>
      </Row>
    </Container>
  </S.Footer>
)

export default Footer
