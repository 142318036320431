import styled from 'styled-components'
import Text from '../Text'

export const Title = styled.h4`
  font-size: 1.625em;
  margin-bottom: 1.0625em;
`

export const Line = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  background: #000;
  margin: 2.25em 0;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    margin-top: 1.5em;
    height: 400px;
    background-size: cover;
  }

  @media screen and (min-width: 768px) {
    padding: 0 0 0 7px;
  }
`

export const SText = styled(Text)`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: .5px;
`
