import React from 'react'

import Page from '../components/Page'
import Text from '../components/Text'
import Container from '../components/Container'
import Section from '../components/Section'
import Overview from '../components/Overview'
import Slider from '../components/Slider'
import QuoteSection from '../components/QuoteSection'
import Objectives from '../components/Objectives'

const AboutPage = () => (
  <Page
    title='The Firm'
    info='Mission gate is a global venture capital firm. We seek to create positive economic impact by investing in great entrepreneurs, who disrupt industries and provide solutions to our everyday problems'
    background='images/pages/WhoAreWe.jpg'
  >
    <Container>
      <Section>
        <Text type='eyebrow'>What We Do</Text>
        <Text type='eyebrowTitle'>
          We invest in great entrepreneurs and support outstanding companies
        </Text>
        <Overview />
      </Section>
      <Text type='headline'>Strategy Proposition</Text>
      <Text type='sub'>Our approach to investing in early stage startups focuses on people, product-market fit and initial traction</Text>
    </Container>
    <Slider />
    <QuoteSection />
    <Objectives />
  </Page>
)

export default AboutPage
