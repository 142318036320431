import styled from 'styled-components'

export const QuoteSection = styled.section`
  background: #000;
  position: relative;
  margin-top: 7em;
  padding-bottom: 1.5em;

@media screen and (max-width: 768px) {
   margin-top: 4em;
  }
`

export const Quote = styled.h2`
  color: #fff;
  font-size: 1.25em;
  line-height: 1.75em;
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: 550px;
  height: auto;
`

export const Author = styled.p`
  font-size: 1.0625em;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: 550px;
  height: auto;

@media screen and (max-width: 768px) {
  padding-bottom: 0em;
  max-width: 200px;
`

export const Photo = styled.img`
  position: relative;
  bottom: 0;
  max-width: 100%;
  height: auto;
	top: -2em;

@media screen and (max-width: 768px) {
   position: static;
   margin-top: 2em;
  }
`
