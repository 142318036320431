import React from 'react'

import * as S from './Text.styles'

const Text = ({ type, children, align }) => {
  if (type === 'headline') return <S.Headline>{children}</S.Headline>
  if (type === 'sub') return <S.SubHeadline align={align}>{children}</S.SubHeadline>
  if (type === 'title') return <S.Title>{children}</S.Title>
  if (type === 'eyebrow') return <S.Eyebrow>{children}</S.Eyebrow>
  if (type === 'eyebrowTitle') return <S.EyebrowTitle>{children}</S.EyebrowTitle>

  return <S.Text>{children}</S.Text>
}

export default Text
