import styled from 'styled-components'
import { Link } from 'gatsby'
import { Col } from 'react-flexbox-grid'

export const Footer = styled.footer`
  background: #000;
  padding-top: 2em;
  padding-bottom: 2em;
`

export const Copyright = styled.p`
  color: #fff;
`

export const Column = styled(Col)`
  position: relative;
  padding-top: .8em;
  border-top: 3px solid #a3a9ae;

  @media screen and (max-width: 767px) {
    margin-bottom: 2em;
  }
`

export const Title = styled.p`
  text-transform: uppercase;
  color: #a3a9ae;
  letter-spacing: .5px;
  margin-bottom: .8em;
`

export const SLink = styled(Link)`
  color: #a3a9ae;
  display: block;
  text-decoration: none;
  letter-spacing: -.5px;

  &:hover {
    color: #fff;
  }
`

export const SF = styled.img`
  height: 90px;
  object-fit: contain;

  @media screen and (min-width: 767px) {
    width: 100%;
  }
`

export const Social = styled.a`
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  color: rgba(255, 255, 255, .4);

  &:hover {
    color: #fff;
  }
`
