import styled from 'styled-components'
import { Col } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Navbar = styled.nav`
  position: fixed;
  width: 100%;
  z-index: 100;
  padding-top: ${props => props.themeChange ? '.625em' : '1.875em'};
  padding-bottom: .625em;
  background-color: ${props => props.themeChange ? '#fff' : null};
  box-shadow: ${props => props.themeChange ? '0 .5em .75em rgba(0, 0, 0, .175)' : null};
  transition: all .5s ease-in-out;
  border-bottom: ${props => props.themeChange ? '1px soldi #a3aaae' : null};

  @media screen and (max-width: 768px) {
    padding-top: 0.625em;
    width: 100%;
    background-color: #fff;
    padding-bottom: 0;
    border-bottom: 1px solid #a3aaae;
  }
`

export const StyledCol = styled(Col)`
  text-align: right;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`

export const Menu = styled.ul`
  list-style: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const MobileMenu = styled.ul`
  list-style: none;
  display: none;

  @media screen and (max-width: 768px) {
    display: ${props => props.toggle ? 'block' : 'none'};
    background: #fff;
  }
`

export const MenuItem = styled.li`
  display: inline-block;

  a {
    padding: .625em .9375em;
    font-size: 1.0625em;
    letter-spacing: 1px;
    color: ${props => props.themeChange ? '#000' : 'rgb(209, 211, 212)'};
    transition: 0;
    text-decoration: none;

    &:hover {
      color: ${props => props.themeChange ? '#104278' : '#fff'};
    }

    &.active {
      color: #104278;
    }
  }

  &:last-child a {
    padding: .625em 0 .625em .9375em;
  }

`

export const MobileMenuItem = styled.li`
  border-bottom: 1px solid #000;
  width: 100%;

  a {
    padding: .9375em 0;
    display: block;
    text-decoration: none;
    color: rgb(49,48,47);
  }
`

export const Toggler = styled(FontAwesomeIcon)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`
