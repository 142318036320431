import styled from 'styled-components'

export const Header = styled.header`
  position: relative;
  background: ${props => `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url('/${props.background}') no-repeat center`};
  background-size: cover;
  height: ${props => props.homepage ? '100vh' : '80vh'};
  text-align: center;
`

export const Content = styled.div`
  color: #fff;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

export const Headline = styled.h1`
  font-size: ${props => props.homepage ? '4.5em' : '3.4375em'};
  margin-bottom: .625em;
`

export const Info = styled.h4`
  font-size: 1.625em;
  max-width: 1000px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
`
