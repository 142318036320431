import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import Container from '../Container'

import * as S from './QuoteSection.styles'

const QuoteSection = () => (
  <S.QuoteSection>
    <Container>
      <Row>
        <Col md={6}>
          <S.Quote>
            "From its inception, Mission Gate has supported bright entrepreneurs from around the world. Whether you are a young founder from Eastern Europe or a serial entrepreneur from Silicon Valley, our mission is to support you with necessary financing and guidance throughout your startup journey."
          </S.Quote>
          <S.Author>George Bachiashvili</S.Author>
          <S.Author>CEO & Founder</S.Author>
        </Col>
        <Col md={6}>
          <S.Photo alt='George Bachiashvili' src='/images/GBa.jpg' />
        </Col>
      </Row>
    </Container>
  </S.QuoteSection>
)

export default QuoteSection
