import React from 'react'

import * as S from './Button.styles'

const Button = ({ appearance, children, gray }, props) => {
  if (appearance === 'primary') return <S.Primary gray={gray} {...props}>{children}</S.Primary>

  return null
}

export default Button
