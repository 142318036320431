import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import SEO from './seo'
import * as S from './Page.styles'

import Navbar from '../Navbar'
import Header from '../Header'
import Footer from '../Footer'

import Text from '../../components/Text'
import Button from '../../components/Button'

const Page = ({ background, title, info, children }) => {
  const [c, setC] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    if (window.location.pathname !== '/contact' && window.location.pathname !== '/insights') {
      setC(true)
    }
  }, [])

  return (
    <>
      <SEO
        title={title}
      />
      <S.GlobalStyle />
      <Navbar />
      <Header
        background={background}
        title={title}
        info={info}
      />
      <S.Page>
        { children }
      </S.Page>
      { c ? (
        <S.ContactSection>
          <Text type='headline'>Get in Touch</Text>
          <Link to='/contact'>
            <Button appearance='primary' gray={true}>Contact Us</Button>
          </Link>
        </S.ContactSection>
      ) : null }
      <Footer />
    </>
  )
}

Page.defaultProps = {
  background: `images/hero.jpg`,
  title: ``,
  info: ``,
  fullscreenHeader: false,
}

Page.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  fullscreenHeader: PropTypes.bool,
}

export default Page
