import styled from 'styled-components'

export const Headline = styled.h2`
  font-size: 2.75em;
  line-height: 1.2em;
  text-align: center;
  margin-bottom: 30px;
`

export const SubHeadline = styled.h5`
  width: 100%;
  max-width: 52em;
  margin-left: auto;
  margin-right: auto;
  text-align: ${props => props.align || 'center'};
  font-size: 1.25em;
  line-height: 1.625em;
  margin-bottom: 30px;
`

export const Title = styled.h4`
  font-size: 1.625em;
  line-height: 1.45em;
  margin-bottom: .5em;
`

export const Text = styled.p`
  font-size: 1.25em;
  font-weight: 100;
  line-height: 1.275em;
`

export const Eyebrow = styled.p`
  font-size: 1.0625em;
  line-height: 1.375em;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const EyebrowTitle = styled.h3`
  max-width: 912px;
  line-height: 1.275em;
  font-size: 2.375em;
  margin-top: .4em;
  margin-bottom: 1.4em;
`
