import styled from 'styled-components'

export const Slider = styled.div``

export const Slide = styled.div``

export const Title = styled.h5`
  font-size: 1.625em;
  line-height: 1.35em;
  margin-bottom: 1.25em;
  padding-right: .5em;
  padding-left: 14px;

@media screen and (max-width: 768px) {
    margin-top: 0.75em;
  }
`

export const Teaser = styled.p`
  font-weight: 100;
  font-size: 1.25em;
  padding-right: .5em;
  padding-left: 14px;
`

export const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  padding-left: 5px;

@media screen and (max-width: 768px) {
    padding-left: 7px;
  }
`

export const Pagination = styled.div`
  max-width: 890px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
`

export const Paginate = styled.button`
  border: 0;
  background: 0;
  outline: none;
  max-width: 200px;
  text-align: center;
  font-size: 1.0625em;
  letter-spacing: 1px;
  margin: 0 20px;
  cursor: pointer;
  color: ${props => props.active ? '#000' : '#a3aaae'};

  @media screen and (max-width: 534px) {
    font-size: .8975em;
    margin: 0 5px;
  }
`

export const NavButton = styled.button`
  border: 0;
  background-color: #000;
  padding: 2em;
  color: #fff;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: none;
  }

  &:active {
    background-color: #104278;
  }
`
